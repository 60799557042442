import Auth from "./Auth";
import Permission from "./Permission";
import UploadFile from "./UploadFile";

import NewsCategory from "./API/NewsCategory";
import WebUsers from "./API/WebUsers";
import News from "./API/News";
import Banner from "./API/Banner";
import Product from "./API/Product";
import ProductCategory from "./API/ProductCategory";
import UserInformation from "./API/UserInformation";
import Order from "./API/Order";
import Village from "./API/Village";
import Wards from "./API/Wards";
import ParcelOfLand from "./API/ParcelOfLand";
import ParcellandInformation from "./API/ParcellandInformation";

const API = {
    Auth: new Auth(),
    UploadFile: new UploadFile(),
    Permission: new Permission(),

    NewsCategory: new NewsCategory(),
    WebUsers: new WebUsers(),
    News: new News(),
    Banner: new Banner(),
    Product: new Product(),
    ProductCategory: new ProductCategory(),
    UserInformation: new UserInformation(),
    Order: new Order(),
    Village: new Village(),
    Wards: new Wards(),
    ParcelOfLand: new ParcelOfLand(),
    ParcellandInformation: new ParcellandInformation(),
};

export default API
