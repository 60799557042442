import {axiosGET, axiosPOST} from '../../Helper/TypeAxios'
import {urlParseParams} from "../../Helper/helpFunction";
const routerRoot = 'product_category';

export default class ProductCategory {
    getData(data) {
        const stringUrl = urlParseParams(data);
        return axiosGET(`/${routerRoot}/list?${stringUrl}`, data, {})
    }

    UpdateStatus(id, data) {
        return axiosPOST(`/${routerRoot}/updateStatus/${id}`, data)
    }

    detailData(id) {
        return axiosGET(`/${routerRoot}/detailData/${id}`)
    }

    updateData(id, data) {
        return axiosPOST(`/${routerRoot}/updateData/${id}`, data)
    }

    createData(data) {
        return axiosPOST(`/${routerRoot}/createData`, data)
    }
}
