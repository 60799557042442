import {axiosPOST} from '../Helper/TypeAxios'

export default class Users {
    login(data) {
        return axiosPOST(`/auth/login`, data, {})
    }

    logout({}, header) {
        return axiosPOST(`/auth/logout`, {}, header)
    }
}
