import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import API from "../../service/API";
import { useSelector } from "react-redux";
import { routerRoot } from './contants';

export default function Create() {
    const loadingData = useSelector(state => state?.loadingAction);
    const history = useHistory();
    const currentUser = useSelector(state => state.currentUser)

    const onFinish = async (values) => {
        const data = {
            name: values?.name ? values.name : null,
        };
        console.log(data, 'data')
        const res = await API[routerRoot].createData(data);
        if (res?.status === 200) {
            // history.push(RouterLink[routerRoot].List);
        }
    };

    return (
        <div>
            <Spin spinning={loadingData?.loading}>
                <div className="box-create-form">
                    <Form style={{ width: '40%', margin: 'auto' }}
                        name="basic"
                        onFinish={onFinish}>
                        <Form.Item label="Tên Xã" name="name" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Input />
                        </Form.Item>
                        <Row justify={'center'}>
                            <Col>
                                <Button type={"primary"} htmlType="submit" danger>Lưu</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Spin>
        </div>
    )
};
