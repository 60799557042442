import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import API from "../../service/API";

export default function ParcelPrint() {
  const params = useParams();
  const id = params?.id
  const [data, setData] = useState({})

  const fetch = async () => {
    const res = await API.ParcellandInformation.ParcelPrint({ id: id })
    if (res?.status === 200) {
      setData(res?.data)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const dataList = [
    {
      title: '',
      list: [
        { stt: `1.3`, value: `Địa chỉ thửa đất: ${data?.ParcelOfLand?.address}` },
        { stt: `1.4`, value: `Diện tích:  ${data?.ParcelOfLandInformation?.rieng}  m2` },
        { stt: `1.5`, value: `Tài liệu đo đạc sử dụng: Bản đồ địa chính` },
      ]
    },
    {
      title: 'II - Người sử dụng đất ',
      list: [
        { stt: `2.1`, value: `Người thứ nhất` },
        { stt: ``, value: `- Tên: ${data?.ParcelOfLand?.fullname1}, năm sinh ${data?.ParcelOfLand?.age1}` },
        { stt: ``, value: `- Giấy tờ pháp nhân hoặc nhân thân: CMND/CCCD số: ${data?.ParcelOfLand?.cmnd1}` },
        { stt: ``, value: `- Địa chỉ: ${data?.ParcelOfLand?.address}` },
        { stt: `2.2 `, value: `Người thứ hai` },
        { stt: ``, value: `- Tên: ${data?.ParcelOfLand?.fullname2}, năm sinh ${data?.ParcelOfLand?.age2}` },
        { stt: ``, value: `- Giấy tờ pháp nhân hoặc nhân thân: CMND/CCCD số: ${data?.ParcelOfLand?.cmnd2} ` },
        { stt: ``, value: `- Địa chỉ: ${data?.ParcelOfLand?.address}` },
      ]
    },
    {
      title: 'III - Quyền sử dụng đất ',
      list: [
        { stt: `3.1`, value: `Hình thức sử dụng: Sử dụng riêng.` },
        { stt: `3.2`, value: `Loại đất: ${data?.ParcelOfLandInformation?.mucdichsudung}` },
        { stt: `3.3`, value: `Thời hạn sử dụng: ${data?.ParcelOfLandInformation?.thoihansudung}` },
        { stt: `3.4`, value: `Nguồn gốc sử dụng: ${data?.ParcelOfLandInformation?.nguongocsudung}` },
        { stt: `3.5`, value: `Nghĩa vụ tài chính: -/-` },
        { stt: `3.6`, value: `Hạn chế sử dụng: -/-` },
        { stt: `3.7`, value: `Quyền sử dụng hạn chế đối với thửa đất liền kề: -/-` },
      ]
    },
    {
      title: 'IV - Tài sản gắn liền với đất  ',
      list: [
        { stt: `4.1`, value: `Tài sản thứ nhất: -/-` },
        { stt: `a)`, value: `Đặc điểm của tài sản:` },
        { stt: `b)`, value: `Chủ sở hữu:` },
        { stt: ``, value: `Hình thức sở hữu:` },
        { stt: ``, value: `Thời hạn sở hữu:` },
        { stt: `c)`, value: `Chủ sở hữu thứ hai:` },
        { stt: `4.2`, value: `Tài sản thứ hai (ghi đối với trường hợp có nhiều tài sản):` },
      ]
    },
    {
      title: 'V - Tình trạng pháp lý về quyền sử dụng đất, quyền sở hữu tài sản gắn liền với đất  ',
      list: [
        { stt: `5.1`, value: `Thời điểm nhận hồ sơ đăng ký lần đầu: -/-` },
        { stt: `5.2`, value: `Thời điểm đăng ký vào sổ địa chính: -/- ` },
        {
          stt: `5.3`, value: <div>
            Giấy tờ về nguồn gốc sử dụng, sở hữu: <br />
            1. Giấy chứng nhận QSDĐ theo Luật Đất Đai 2013,  số {data?.ParcelOfLandInformation?.sophathanhgcn} <br />
            2. Giấy tờ pháp lý
          </div>
        },
        { stt: `5.4`, value: `Giấy chứng nhận: Số seri: ${data?.ParcelOfLandInformation?.sovaosocapgcnqsdd} ,     Số vào sổ cấp GCN: ` },
        { stt: `5.5`, value: <div>Hồ sơ thủ tục đăng ký số: <input name="hsttdks" /></div> },
      ]
    },
    {
      title: 'VI - Thay đổi về sử dụng đất, sở hữu tài sản gắn liền với đất',
      list: [
      ]
    }
  ]
  return (
    <>
      <form method="get" action={`http://export.sodiachinhbudang.xyz/report?`}>
        <input name="id" value={id} hidden />
        <table className="qiAJkMhfXE">
          <tbody>
            <tr>
              <td className="NuHleMZhmL pJRmDMWYKi" colSpan={4}>Mẫu số 01/ĐK</td>
            </tr>
            <tr>
              <td className="NuHleMZhmL zuMqFcMdgb" colSpan={4}>SỔ ĐỊA CHÍNH (ĐIỆN TỬ)</td>
            </tr>
            <tr>
              <td className="NuHleMZhmL zuMqFcMdgb" colSpan={4}>PHẦN ĐĂNG KÝ THỬA ĐẤT</td>
            </tr>
            <tr>
              <td className="NuHleMZhmL" colSpan={4}>I - Thửa đất </td>
            </tr>
            <tr>
              <td className="NuHleMZhmL">1.1</td>
              <td className="NuHleMZhmL">Số thửa: {data?.ParcelOfLandInformation?.sothututhuadat}</td>
              <td className="NuHleMZhmL">1.2</td>
              <td className="NuHleMZhmL">Số tờ bản đồ: {data?.ParcelOfLandInformation?.sothutubando}</td>
            </tr>
            {
              dataList && dataList.length > 0 ? dataList.map((item) => {
                return (
                  <>
                    {
                      item?.title && <tr>
                        <td className="NuHleMZhmL" colSpan={4}>{item?.title}</td>
                      </tr>
                    }
                    {
                      item.list.map((item2) => {
                        return (
                          <tr>
                            <td className="NuHleMZhmL">{item2?.stt}</td>
                            <td className="NuHleMZhmL" colSpan={3}>{item2?.value}</td>
                          </tr>
                        )
                      })
                    }
                  </>
                )
              }) : null
            }

            <tr>
              <td className="NuHleMZhmL" colSpan={2}>Thời điểm đăng ký</td>
              <td className="NuHleMZhmL" colSpan={2}>Nội dung thay đổi và cơ sở pháp lý</td>
            </tr>
            {
              data?.ParcelOfLandMore && data?.ParcelOfLandMore.length > 0 ? data?.ParcelOfLandMore.map((item) => {
                return (
                  <tr>
                    <td className="NuHleMZhmL" colSpan={2}>{item?.ngaythangnam}</td>
                    <td className="NuHleMZhmL" colSpan={2}>{item?.noidung}</td>
                  </tr>
                )
              }) : null
            }
          </tbody>
        </table>
        <div style={{ textAlign: 'center', marginTop: 24 }}>
          <button type="submit" style={{ width: 200, height: 50 }}>In</button>
        </div>
      </form>
    </>
  )
}