import {ACTION_STOP, ACTION_DO} from "../../constants/constants";


const loadingAction = (state = {loading: false}, action) => {
    switch (action.type) {
        case ACTION_DO:
            return {loading: true};
        case ACTION_STOP:
            return {loading: false};
        default:
            return state;
    }
};

export default loadingAction;
