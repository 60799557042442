import { axiosGET, axiosPOST } from '../../Helper/TypeAxios'
import { urlParseParams } from "../../Helper/helpFunction";

const routerRoot = 'parcelland';

export default class ParcelOfLand {
    getData(data) {
        const stringUrl = urlParseParams(data);
        return axiosGET(`/${routerRoot}/list?${stringUrl}`, data, {})
    }

    UpdateStatus(id, data) {
        return axiosPOST(`/${routerRoot}/updateStatus/${id}`, data)
    }

    detailData(id) {
        return axiosGET(`/${routerRoot}/detailData/${id}`)
    }

    updateData(id, data) {
        return axiosPOST(`/${routerRoot}/updateData/${id}`, data)
    }

    createData(data) {
        return axiosPOST(`/${routerRoot}/createData`, data)
    }

    createDataThua(data) {
        return axiosPOST(`/${routerRoot}/createDataThua`, data)
    }

    deleteData(id) {
        return axiosGET(`/${routerRoot}/deleteData/${id}`, {})
    }

    deleteDataVillage(id) {
        return axiosGET(`/${routerRoot}/deleteDataVillage/${id}`, {})
    }

    updateDataThua(id, data) {
        return axiosPOST(`/${routerRoot}/updateDataThua/${id}`, data)
    }

    uploadFileExcel(id, data) {
        return axiosPOST(`/${routerRoot}/uploadFileExcel/${id}`, data)
    }
}
