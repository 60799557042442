import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from "antd";
import { useState } from 'react';
import API from '../../service/API';
import Excel from 'exceljs'
import moment from 'moment';

export default function UploadFile({ params, maxFile = 1, dataPush }) {

  const [fileList, setLileList] = useState([])
  const [uploading, setUploading] = useState(false)

  const handleUpload = async () => {
    if (fileList?.fileList.length > 0) {
      // const formData = new FormData();
      // fileList?.fileList.map(file => {
      //   formData.append('files[]', file);
      // });
      const wb = new Excel.Workbook();
      const reader = new FileReader()

      // setUploading(true)
      reader.readAsArrayBuffer(fileList?.fileList?.[0])
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then(workbook => {
          let listData = [];
          workbook.eachSheet((sheet, id) => {
            let trang = ''
            let fullname1 = ''
            let cmnd1 = ''
            let age1 = ''
            let fullname2 = ''
            let cmnd2 = ''
            let age2 = ''
            let diachi = ''
            let thuadat = []
            let getThuadat = true
            let thaydoi = []
            let getThongtinthaydoi = false
            if (sheet?.state === 'visible') {
              sheet.eachRow((row, rowIndex) => {
                if (makeData(row?.values, rowIndex)?.trang) { trang = makeData(row?.values, rowIndex)?.trang; }
                if (makeData(row?.values, rowIndex)?.fullname1) { fullname1 = makeData(row?.values, rowIndex)?.fullname1; }
                if (makeData(row?.values, rowIndex)?.cmnd1) { cmnd1 = makeData(row?.values, rowIndex)?.cmnd1; }
                if (makeData(row?.values, rowIndex)?.age1) { age1 = makeData(row?.values, rowIndex)?.age1; }
                if (makeData(row?.values, rowIndex)?.fullname2) { fullname2 = makeData(row?.values, rowIndex)?.fullname2; }
                if (makeData(row?.values, rowIndex)?.cmnd2) { cmnd2 = makeData(row?.values, rowIndex)?.cmnd2; }
                if (makeData(row?.values, rowIndex)?.age2) { age2 = makeData(row?.values, rowIndex)?.age2; }
                if (makeData(row?.values, rowIndex)?.diachi) { diachi = makeData(row?.values, rowIndex)?.diachi; }
                thuadat = makeRow10(row?.values, rowIndex, thuadat, getThuadat)?.listOld;
                getThuadat = makeRow10(row?.values, rowIndex, thuadat, getThuadat)?.getData;
                thaydoi = makeRow11(row?.values, rowIndex, thaydoi, getThongtinthaydoi)?.listOld;
                getThongtinthaydoi = makeRow11(row?.values, rowIndex, thaydoi, getThongtinthaydoi)?.getData;
                // console.log(row.values, rowIndex)
              })
              console.log(trang, fullname1, cmnd1, age1, fullname2, cmnd2, age2, diachi, thuadat, thaydoi);
              listData.push({
                trang: trang,
                fullname1: fullname1,
                cmnd1: cmnd1,
                age1: age1,
                fullname2: fullname2,
                cmnd2: cmnd2,
                age2: age2,
                address: diachi,
                note: '',
                thuadat: thuadat,
                thaydoi: thaydoi,
                page_sheet: trang,

              })
            }
          })
          dataPush(listData)
          setUploading(false)
        })
      }


      // await workbook.xlsx.readFile("sample.xlsx").then(function () {
      //   var workSheet = workbook.getWorksheet("one");

      //   workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      //     console.log(row, rowNumber, 'row, rowNumber');

      //     // currRow = workSheet.getRow(rowNumber);
      //     // console.log("User Name :" + currRow.getCell(1).value + ", Password :" + currRow.getCell(2).value);
      //     // console.log("User Name :" + row.values[1] + ", Password :" + row.values[2]);

      //     // assert.equal(currRow.getCell(2).type, Excel.ValueType.Number);
      //     //  console.log("Row " + rowNumber + " = " + JSON.stringify(row.values));
      //   });
      // })
      // setUploading(true)
      // const res = await API.ParcelOfLand.uploadFileExcel(params?.id, formData)
      // if (res?.status === 200) {
      //   setUploading(false)
      // }
    }
  };

  const makeData = (value, index) => {
    if (index === 1) {
      return makeRow1(value)
    }
    if (index === 3) {
      return makeRow3(value)
    }
    if (index === 4) {
      return makeRow4(value)
    }
    if (index === 5) {
      return makeRow5(value)
    }
  }

  const makeRow1 = (value) => {
    let text = value?.[10]
    text = text.replaceAll('Trang', '')
    text = text.replaceAll('số', '')
    text = text.replaceAll(':', '')
    text = text.replaceAll(' ', '')
    return {
      trang: text
    }
  }
  const removeText = (item) => {
    if ((item.toLowerCase()) === 'hộ') { return null }
    if ((item.toLowerCase()) === 'ông:') { return null }
    if ((item.toLowerCase()) === 'sinh') { return null }
    if ((item.toLowerCase()) === 'năm') { return null }
    if ((item.toLowerCase()) === 'năm:') { return null }
    if ((item.toLowerCase()) === 'cmnd') { return null }
    if ((item.toLowerCase()) === 'số') { return null }
    if ((item.toLowerCase()) === 'số:') { return null }
    if ((item.toLowerCase()) === 'và') { return null }
    if ((item.toLowerCase()) === 'bà') { return null }
    if ((item.toLowerCase()) === 'bà:') { return null }
    if ((item.toLowerCase()) === 'địa') { return null }
    if ((item.toLowerCase()) === 'chỉ') { return null }
    if ((item.toLowerCase()) === 'chỉ:') { return null }
    return item
  }
  const makeRow3 = (value) => {
    let text = value?.[1]
    if (!text) {
      return null
    }
    let listText = text.split(' ')
    let fullname = ''
    let cmnd = ''
    let age = ''
    listText = listText.filter((item) => {
      item = removeText(item)
      return item
    })

    listText.map((item) => {
      if (!isNaN(parseInt(item)) && parseInt(item) > 4000) {
        cmnd = item
      } else if (!isNaN(parseInt(item)) && parseInt(item) < 4000) {
        age = item
      } else {
        fullname = fullname + ' ' + item
      }
    })

    return {
      fullname1: fullname,
      cmnd1: cmnd,
      age1: age,
    }
  }
  const makeRow4 = (value) => {
    let text = value?.[1]
    if (!text) {
      return null
    }
    let listText = text.split(' ')
    let fullname = ''
    let cmnd = ''
    let age = ''
    listText = listText.filter((item) => {
      item = removeText(item)
      return item
    })

    listText.map((item) => {
      if (!isNaN(parseInt(item)) && parseInt(item) > 4000) {
        cmnd = item
      } else if (!isNaN(parseInt(item)) && parseInt(item) < 4000) {
        age = item
      } else {
        fullname = fullname + ' ' + item
      }
    })

    return {
      fullname2: fullname,
      cmnd2: cmnd,
      age2: age,
    }
  }

  const makeRow5 = (value) => {
    let text = value?.[1]
    if (!text) {
      return null
    }
    let listText = text.split(' ')
    let diachi = ''
    listText = listText.filter((item) => {
      item = removeText(item)
      return item
    })

    listText.map((item) => {
      diachi = diachi + ' ' + item
    })

    return {
      diachi: diachi,
    }
  }

  const makeRow10 = (value, index, listOld = [], getData = true) => {
    if (value?.[1] && typeof value?.[1] === 'string' && (value?.[1].toLowerCase()).indexOf('những thay đổi trong quá trình') > -1) {
      getData = false
    }
    if (index > 9 && getData) {
      const data = [{
        ngaythangnamvao: value?.[1] ? moment(value?.[1]).format('DD/MM/YYYY') : '',
        sothututhuadat: value?.[2] ? value?.[2] : '',
        sothutubando: value?.[3] ? value?.[3] : '',
        rieng: value?.[4] ? value?.[4] : '',
        chung: value?.[5] ? value?.[5] : '',
        mucdichsudung: value?.[6] ? value?.[6] : '',
        thoihansudung: value?.[7] ? value?.[7] : '',
        nguongocsudung: value?.[8] ? value?.[8] : '',
        sophathanhgcn: value?.[9] ? value?.[9] : '',
        sovaosocapgcnqsdd: value?.[10] ? value?.[10] : '',
      }]
      return {
        listOld: listOld.concat(data),
        getData: getData
      };
    }
    return {
      listOld: listOld,
      getData: getData
    }
  }

  const makeRow11 = (value, index, listOld, getData = false) => {
    if (index > 9 && getData && value?.[1] && value?.[3]) {
      const data = [{
        sothututhuadat: value?.[1] ? value?.[1] : '',
        ngaythangnam: value?.[2] ? value?.[2] : '',
        noidung: value?.[3] ? value?.[3] : '',
      }]
      return {
        listOld: listOld.concat(data),
        getData: getData
      };
    }
    if (index > 74) {
      getData = false
    }
    if (value?.[3] && typeof value?.[3] === 'string' && (value?.[3].toLowerCase()).indexOf('nội dung ghi chú hoặc biến động') > -1) {
      getData = true
    }
    return {
      listOld: listOld,
      getData: getData
    }
  }

  const props = {
    beforeUpload: file => {
      setLileList({
        fileList: [...fileList, file],
      })
      return false;
    },
    // fileList,
  };

  return (
    <>
      <Upload {...props} maxCount={maxFile}>
        <Button icon={<UploadOutlined />}>Click to Upload excel</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
    </>
  )
}