import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, notification, Row, Select, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import API from "../../service/API";
import { RouterLink } from "../../constants/constants";
import { useSelector } from "react-redux";
import { routerRoot } from './contants';

export default function Edit() {
    const [form] = Form.useForm();
    const params = useParams();
    const id = params?.id;
    const history = useHistory();
    const loadingData = useSelector(state => state?.loadingAction);
    const [Domain, setDomain] = useState({})
    const currentUser = useSelector(state => state.currentUser)

    const fetchDomain = async () => {
        const res = await API.WebUsers.getData({ page: 1, limit: 100 })
        if (res?.status) {
            setDomain(res?.data)
        }
    }

    const fetchDetail = async () => {
        const res = await API[routerRoot].detailData(id);
        if (res?.status === 200) {
            form.setFieldsValue({
                title: res?.data?.title ? res?.data.title : null,
                domain_name: res?.data?.domain_name ? res?.data.domain_name : null,
                status: res?.data?.status ? res?.data.status : null,
            })
        }
    };

    useEffect(() => {
        fetchDomain().then();
        fetchDetail().then();
    }, []);

    const onFinish = async (values) => {
        const data = {
            title: values?.title ? values.title : null,
            domain_name: values?.domain_name ? values.domain_name : null,
            status: values?.status ? values.status : null,
        };
        const res = await API[routerRoot].updateData(id, data);
        if (res?.status === 200) {
            history.push(RouterLink[routerRoot].List);
        }
    };

    return (
        <div>
            <Spin spinning={loadingData?.loading}>
                <div className="box-create-form">
                    <Form form={form} style={{ width: '40%', margin: 'auto' }}
                        name="basic"
                        onFinish={onFinish}>
                        <Form.Item label="Trang" name="domain_name" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Select>
                                <Select.Option value={''}>Tất cả</Select.Option>
                                {
                                    Domain?.data && Domain?.data.length > 0 ? Domain?.data.map((item, key) => {
                                        const domainName = item?.domain_list ? JSON.parse(item?.domain_list)[0] : null
                                        if (currentUser?.user?.permission === 0 ||
                                            (currentUser?.user?.permission === 1 && currentUser?.user?.usersinformation_info?.domain_name === domainName)) {
                                            return (<Select.Option key={key} value={domainName}>{domainName}</Select.Option>)
                                        }
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Tên menu" name="title" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Trạng thái" name="status" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Select>
                                <Select.Option value={1}>Kích hoạt</Select.Option>
                                <Select.Option value={2}>Không kích hoạt</Select.Option>
                            </Select>
                        </Form.Item>
                        <Row justify={'center'}>
                            <Col>
                                <Button type={"primary"} htmlType="submit" danger>Lưu</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Spin>
        </div>
    )
};
