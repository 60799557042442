import { ExclamationCircleOutlined, CheckCircleOutlined, HeartFilled, CopyOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Pagination, Row, Spin, Table, Tag, Upload, message, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { RouterLink } from "../../constants/constants";
import API from "../../service/API";
import Search from './search';
import { routerRoot } from './contants';
import { useSelector } from 'react-redux'
import Home from '../PageExcel/Home'
import TableInfo from './TableInfo'

export default function List() {
    const urlParams = useParams()
    const id = urlParams?.id
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: [], total: 0 });
    const [params, setParams] = useState({ page: 1, limit: 10, orderByName: 'page_sheet', orderBySort: 'asc' });
    const history = useHistory();
    const [detail, setDetail] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [wards, setWards] = useState({})
    const [village, setVillage] = useState({})
    const [dataSave, setDataSave] = useState({})
    const [detailEdit, setDetailEdit] = useState(null)
    const [detailEditSave, setDetailEditSave] = useState(null)
    const [modalViewThua, setModalViewThua] = useState(false);
    const [ModalConfirmPass, setModalConfirmPass] = useState(false)
    const [PasswordConfirm, setPasswordConfirm] = useState('')


    const columns = [
        {
            title: <strong>#</strong>,
            render: (text, record, index) => <div>Trang {record?.page_sheet}</div>,
        },
        {
            title: 'Địa phận',
            render: record => {
                return <div>
                    <div>Xã: <span className='qshmfigade'>{record?.village_info?.wards_info?.name}</span></div>
                    <div>Thôn: <span className='qshmfigade'>{record?.village_info?.name}</span></div>
                </div>
            }
        },
        {
            title: 'NGƯỜI SỬ DỤNG ĐẤT',
            render: record => {
                return <div style={{ width: 200 }}>
                    <div>Chủ Hộ 1: {record?.fullname1} - {record?.age1} - {record?.cmnd1}</div>
                    {record?.fullname2 ? <div>{record?.fullname2} - {record?.age2} - {record?.cmnd2}</div> : null}
                    Địa chỉ: {record?.address}
                    <div>
                        <Button style={{ marginTop: 6 }} onClick={() => handeEditThua(record?.id)}>Sửa</Button>
                    </div>
                </div>
            }
        },
        {
            title: 'THỬA ĐẤT',
            render: record => {
                return <div>
                    {
                        record?.thuadat ? record?.thuadat.map((item) => {
                            return (
                                <div className={`AMIaAVtBqX ${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>
                                    {
                                        (!item?.sothututhuadat && !item?.sothutubando && !item?.sophathanhgcn) ? null : <>
                                            <div className='CsjgxirKgP' onClick={() => window.open(`/admin/parcelland/print/${item?.id}`)}><CopyOutlined /></div>
                                            <Tag color={"volcano"} className="esiqveqlal">
                                                {item?.ngaythangnamvao ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Ngày tháng năm vào: {item?.ngaythangnamvao}</Tag> : null}
                                                {item?.sothututhuadat ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Thứ tự: {item?.sothututhuadat}</Tag> : null}
                                                {item?.sothutubando ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Thứ tự từ bản đồ: {item?.sothutubando}</Tag> : null}
                                                {item?.rieng ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Diện tích riêng: {item?.rieng}</Tag> : null}
                                                {item?.chung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Diện tích sử dụng chung: {item?.chung}</Tag> : null}
                                                {item?.mucdichsudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Mục đích sử dụng: {item?.mucdichsudung}</Tag> : null}
                                                {item?.thoihansudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Thời gian sử dụng: {item?.thoihansudung}</Tag> : null}
                                                {item?.nguongocsudung ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Nguồn gốc sử dụng: {item?.nguongocsudung}</Tag> : null}
                                                {item?.sophathanhgcn ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số Phát Hành CNQ SDĐ: {item?.sophathanhgcn}</Tag> : null}
                                                {item?.sovaosocapgcnqsdd ? <Tag className={`${item?.checklinethua === 'throght-thua' ? 'dibwpioxhv' : ''}`}>Số vào cấp GCN QSDĐ{item?.sovaosocapgcnqsdd}</Tag> : null}
                                            </Tag>
                                        </>
                                    }

                                </div>
                            )
                        }) : null
                    }
                </div>
            }
        },
        {
            title: 'NHỮNG THAY ĐỔI TRONG QUÁ TRÌNH SỬ DỤNG ĐẤT VÀ GHI CHÚ',
            render: record => {
                return <div style={{ width: 400 }} className="">
                    {
                        record?.thaydoi ? record?.thaydoi.map((item) => {
                            return (
                                <div className={`amitupwtjq ${item?.checklinethua === 'throght-line' ? 'dibwpioxhv' : ''}`}>
                                    {item?.thonlinktosheet && item?.thuadatlinktosheet ? <CheckCircleOutlined className='mxpefrcshf' /> : null}
                                    {
                                        (!item?.sothututhuadat && !item?.noidung) ? null : <Tag color={"volcano"} className="esiqveqlal">
                                            {item?.sothututhuadat ? <Tag className={`${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>Số thứ tự thửa: {item?.sothututhuadat}</Tag> : null}
                                            {item?.ngaythangnam ? <Tag className={`${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>Ngày tháng: {item?.ngaythangnam}</Tag> : null}
                                            {item?.noidung ? <Tag onClick={() => {
                                                handeEditThua(item?.thuadatlinktosheet)
                                            }} className={`wtcygdslpc ${item?.checkline === 'throght-line' ? 'dibwpioxhv' : ''}`}>
                                                Thông tin: {item?.noidung}
                                            </Tag> : null}
                                        </Tag>
                                    }

                                </div>
                            )
                        }) : null
                    }
                </div>
            }
        },
        {
            title: 'Thao tác',
            render: record => {
                return (
                    <div className="box-btn-list">
                        <Button type={'danger'} onClick={() => handleDeleteItem(record?.id)}>Xóa</Button>
                    </div>
                )
            },
        }
    ];

    const handleDeleteItem = async (id) => {
        Modal.confirm({
            title: 'Thông báo!',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có chắc muốn xóa',
            okText: 'Đồng ý',
            cancelText: 'Hủy',
            onOk: async () => {
                await API[routerRoot].deleteData(id);
                fetch(params).then()
            }
        });

    }

    const handeEditThua = async (id) => {
        if (id) {
            const res = await API[routerRoot].detailData(id);
            if (res?.status === 200) {
                setDetailEdit(res?.data)
                setTimeout(() => {
                    setModalViewThua(true)
                }, 800)
            }
        }
    }

    const handleUpdateThua = async () => {
        await API.ParcelOfLand.updateDataThua(detailEditSave?.id, detailEditSave);
        fetch(params).then()
    }

    useEffect(() => {
        fetch(params).then()
        fetchDetail().then()
    }, [params]);

    const fetch = async (params = {}) => {
        if (id) {
            params.village_id = id
        }
        setLoading(true);
        const res = await API[routerRoot].getData(params);
        if (res?.status === 200) {
            setData({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
        setLoading(false);
    };

    const fetchDetail = async () => {
        if (id) {
            const res = await API.Village.detailData(id);
            if (res?.status === 200) {
                setDetail(res?.data)
            }
        }
    }

    const handleSearch = (values) => {
        setParams({ ...params, ...values })
    }

    const handlePage = (page, perPage) => {
        setParams({ ...params, page: page, limit: perPage })
    };

    const fetchWards = async (params = {}) => {
        const res = await API.Wards.getData(params);
        if (res?.status === 200) {
            setWards({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
    };

    const fetchVillage = async (params = {}) => {
        const res = await API.Village.getData(params);
        if (res?.status === 200) {
            setVillage({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
    };

    useEffect(() => {
        fetchWards({ limit: 5000, page: 1 });
        fetchVillage({ limit: 5000, page: 1 });
    }, [])

    const handleCreateOrUpdateData = async () => {
        setLoading(true)
        const res = await API[routerRoot].createDataThua({ village_id: id, dataSave: dataSave });
        if (res?.status === 200) {
            fetch(params).then()
            setLoading(false)
        }
    }

    const handleCreateThua = async () => {
        if (id) {
            setIsModalVisible(true);
        } else {
            Modal.confirm({
                title: 'Thông báo!',
                icon: <ExclamationCircleOutlined />,
                content: 'Bạn cần chọn thôn trước khi thêm thửa',
                okText: 'Đồng ý',
                cancelText: 'Hủy',
                onOk: async () => {
                    history.push(RouterLink.Village.List)
                    if (id) {
                        params.village_id = id
                    }
                    fetch(params).then()
                }
            });
        }
    }

    const handleDeleteAll = () => {
        if (PasswordConfirm === 'sodiachinhbudang2023') {
            Modal.confirm({
                title: 'Thông báo!',
                icon: <ExclamationCircleOutlined />,
                content: 'Bạn cần xóa toàn bộ thửa không?',
                okText: 'Đồng ý',
                cancelText: 'Hủy',
                onOk: async () => {
                    await API[routerRoot].deleteDataVillage(id);
                    fetch(params).then()
                }
            });
        } else {
            message.error({ content: 'Đã có lỗi xảy ra!' });
        }
    }

    return (
        <div>
            <Search data={(value) => handleSearch(value)} village={village} wards={wards} />
            {detail?.name ? <div className="QBSAiqWULo">{detail?.name}</div> : null}
            <Row style={{ marginBottom: 10 }}>
                <Col><Button type={'primary'} onClick={() => handleCreateThua()}>Tạo mới</Button></Col>
                <Col style={{ marginLeft: 4 }}>
                    <Button type={'danger'} onClick={() => setModalConfirmPass(true)}>Xóa toàn bộ</Button>
                </Col>
            </Row>
            <Spin spinning={loading}>
                <Row justify={'center'} style={{ marginTop: '10px' }}>
                    <Col>
                        <Pagination
                            current={params.page}
                            onChange={(page, perPage) => handlePage(page, perPage)}
                            total={data?.total}
                            showSizeChanger={true}
                            defaultPageSize={params?.perPage}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </Col>
                </Row>
                <Table
                    dataSource={data?.data}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{ x: 1200 }}
                    pagination={false}
                />
                <Row justify={'center'} style={{ marginTop: '10px' }}>
                    <Col>
                        <Pagination
                            current={params.page}
                            onChange={(page, perPage) => handlePage(page, perPage)}
                            total={data?.total}
                            showSizeChanger={true}
                            defaultPageSize={params?.perPage}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </Col>
                </Row>
            </Spin>
            <Modal title="Thêm thửa" width={'100%'} footer={false} visible={isModalVisible} closable={false}
                onOk={() => setIsModalVisible(!isModalVisible)} onCancel={() => setIsModalVisible(!isModalVisible)}>
                {
                    loading ? <Button className="vowvrqnxli">Hoàn thành nhập <Spin></Spin></Button> :
                        <Button onClick={() => handleCreateOrUpdateData()} className="vowvrqnxli">Hoàn thành nhập</Button>
                }
                <Home dataSave={dataSave} dataInsert={(value) => setDataSave(value)} />
            </Modal>
            <Modal title="Xem thông tin hoặc chỉnh sửa thửa" width={'100%'} footer={false} visible={modalViewThua} closable={false}
                onOk={() => setModalViewThua(!modalViewThua)} onCancel={() => setModalViewThua(!modalViewThua)}>
                <Button onClick={() => handleUpdateThua()}>Hoàn Thành Chỉnh Sửa</Button>
                <TableInfo item={detailEdit} UpdateData={(value) => setDetailEditSave(value)} />
            </Modal>
            <Modal title="Xác nhận xóa toàn bộ" footer={false} visible={ModalConfirmPass} closable={false}
                onOk={() => setModalConfirmPass(!ModalConfirmPass)} onCancel={() => setModalConfirmPass(!ModalConfirmPass)}>
                <Input.Password style={{ marginBottom: 24 }} value={PasswordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)} />
                <Button onClick={() => handleDeleteAll()}>Xác nhận</Button>
            </Modal>
        </div>
    )
}
