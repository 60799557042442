import { Col, Row } from 'antd'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import API from '../../service/API';
import { keys } from 'lodash';

export default function HomeAdmin() {
    const [accessWeb, setAccessWeb] = useState({
        dateAccess: [],
        valueAccess: []
    })

    useEffect(() => {
        // const fetch = async () => {
        //     const res = await API.Home.getReport();
        //     if (res?.status === 200) {
        //         if (res?.data.access_web) {
        //             const dateAccess = []
        //             const valueAccess = []
        //             Object.values(res?.data.access_web).map((item, key) => {
        //                 dateAccess.push(Object.keys(res?.data.access_web)[key])
        //                 valueAccess.push(item)
        //             })
        //             setAccessWeb({
        //                 dateAccess: dateAccess,
        //                 valueAccess: valueAccess,
        //             })
        //         }
        //         // setData(res?.data)
        //     }
        // }
        // fetch()
    }, [])

    const options = {
        chart: {
            renderTo: 'chart',
            defaultSeriesType: 'areaspline'
        },
        title: {
            text: 'Thống kê truy cập'
        },
        yAxis: {
            title: {
                text: 'Số lượng'
            }
        },
        xAxis: {
            categories: accessWeb?.dateAccess
        },
        series: [{
            name: 'Ngày truy cập',
            data: accessWeb?.valueAccess
        }]
    };
    const options2 = {
        title: {
            text: 'Thống kê đơn hàng'
        },
        yAxis: {
            title: {
                text: 'Số lượng'
            }
        },
        xAxis: {
            categories: accessWeb?.dateAccess
        },
        series: [
            {
                name: 'Số đơn/Ngày',
                data: [0, 0, 0, 0, 0]
            }
        ]
    };
    return (
        <>
            <Row>
                <Col span={12}>
                    <div style={{ width: '100%' }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options2}
                    />
                </Col>
            </Row>
        </>
    )
}
