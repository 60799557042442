import { Button, Col, Pagination, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { RouterLink } from "../../constants/constants";
import API from "../../service/API";
import { routerRoot } from './contants';
import Search from './search';

export default function List() {
    const urlParams = useParams()
    const id = urlParams?.id
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [data, setData] = useState({ data: [], total: 0 });
    const [params, setParams] = useState({ page: 1, limit: 10, orderByName: 'name', orderBySort: 'asc' });
    const history = useHistory();

    const columns = [
        {
            title: <strong>#</strong>,
            render: (text, record, index) => <div>{(params?.page - 1) * params?.limit + (index + 1)}</div>,
        },
        {
            title: 'Tên thôn',
            render: record => {
                return <div>
                    <div>{record?.name}</div>
                </div>
            }
        },
        {
            title: 'Tổng số thửa',
            render: record => {
                return <div>
                    <div>{record?.village_total_count}/{record?.total}</div>
                </div>
            }
        },
        {
            title: 'Thao tác',
            render: record => {
                return (
                    <div className="box-btn-list">
                        <Button type={'default'} style={{ marginRight: 5 }}>
                            <Link to={RouterLink.ParcelOfLand.View(record?.id)}>Xem thông tin thửa</Link>
                        </Button>
                        <Button type={'default'} style={{ marginRight: 5 }}>
                            <Link to={RouterLink[routerRoot].Edit(record?.id)}>Sửa</Link>
                        </Button>
                    </div>
                )
            },
        }
    ];

    useEffect(() => {
        fetch(params).then()
        fetchDetail().then()
    }, [params]);

    const fetch = async (params = {}) => {
        setLoading(true);
        if (id) {
            params.wards_id = id
        }
        const res = await API[routerRoot].getData(params);
        if (res?.status === 200) {
            setData({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
        setLoading(false);
    };

    const fetchDetail = async () => {
        if (id) {
            const res = await API.Wards.detailData(id);
            if (res?.status === 200) {
                setDetail(res?.data)
            }
        }
    }

    const handleSearch = (values) => {
        setParams({ ...params, ...values })
    }

    const handlePage = (page, perPage) => {
        setParams({ ...params, page: page, limit: perPage })
    };

    return (
        <div>
            <Search data={(value) => handleSearch(value)} />
            {detail?.name ? <div className="QBSAiqWULo">{detail?.name}</div> : null}
            <Row style={{ marginBottom: 10 }}>
                <Col><Button type={'primary'} onClick={() => history.push(RouterLink[routerRoot].Create)}>Tạo mới</Button></Col>
            </Row>
            <Spin spinning={loading}>
                <Table
                    dataSource={data?.data}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{ x: 1200 }}
                    pagination={false}
                />
                <Row justify={'center'} style={{ marginTop: '10px' }}>
                    <Col>
                        <Pagination
                            current={params.page}
                            onChange={(page, perPage) => handlePage(page, perPage)}
                            total={data?.total}
                            showSizeChanger={true}
                            defaultPageSize={params?.perPage}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
