import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import API from '../../service/API'
import { useHistory, useParams } from "react-router-dom";

export default function PermissionCreate() {
    const params = useParams();
    const id = params?.id;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [listRouter, setListRouter] = useState(null);

    useEffect(() => {
        fetchDetail().then();
    }, []);

    const fetchDetail = async () => {
        const res = await API.Permission.detailData(id);
        console.log(res, 'res')

        if (res?.status === 200) {
            let listPermission = []
            if (res?.data?.permission_group_router_info) {
                res?.data?.permission_group_router_info.map((item, key) => {
                    listPermission.push(item?.router_name)
                })
            }
            form.setFieldsValue({
                name: res?.data?.name ? res?.data?.name : null,
                status: res?.data?.status ? res?.data?.status : null,
                list_permission: listPermission,
            })
        }
    };

    const fetchRouter = async () => {
        const res = await API.Permission.getAll();
        let listOption = [];

        if (res?.status === 200) {
            res?.data?.map((item) => {
                listOption.push(<Select.Option key={item?.router} value={item?.router}>{item?.name}</Select.Option>)
            })
            setListRouter(listOption)
        }
    };

    useEffect(() => {
        fetchRouter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
        const data = {
            name: values?.name ? values.name : null,
            status: values?.status ? values.status : null,
        };

        setTimeout(async () => {
            const res = await API.Permission.updateData(id, data);
            if (res?.status === 200) {
                const group_id = res?.data
                if (group_id) {
                    await API.Permission.createGroup({
                        group_id: group_id,
                        list_permission: values?.list_permission
                    });
                }
                // history.push(RouterLink.EtPermissionGroup.List);
            }
            setLoading(false);
        }, 1500)
    };

    return (
        <>
            <Row justify="center">
                <Col span={6}>
                    <Form form={form} name="basic" onFinish={onFinish}>
                        <Form.Item
                            label="Tên nhóm quyền"
                            name="name"
                            rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Quyền"
                            name="list_permission"
                            rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]}
                        >
                            <Select mode="multiple" className="kllzlteejc"
                                size={10}>
                                {listRouter}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Trạng thái"
                            name="status"
                            rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]}
                        >
                            <Select>
                                <Select.Option value={1}>Kích hoạt</Select.Option>
                                <Select.Option value={2}>Không kích hoạt</Select.Option>
                            </Select>
                        </Form.Item>
                        <Row justify={'center'}>
                            <div className="box-btn-submit-form">
                                <Button type={"primary"} htmlType="submit" danger>Cập nhật</Button>
                            </div>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}