import { useEffect, useState } from "react";
import { Button, Col, Pagination, Row, Table, Modal, Spin, Tag } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { RouterLink } from "../../constants/constants";
import API from "../../service/API";
import { Link, useHistory } from "react-router-dom";

export default function PermissionSetting() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: [], total: 0 });
    const [params, setParams] = useState({ page: 1, perPage: 10 });
    const history = useHistory()

    const columns = [
        {
            title: <strong>#</strong>,
            render: (text, record, index) => <div>{(params?.page - 1) * params?.perPage + (index + 1)}</div>,
        },
        {
            title: 'Tên',
            key: 'name',
            render: record => {
                return <div>{record?.name}</div>
            }
        },
        {
            title: 'Nhóm quyền',
            render: record => {
                return <div>
                    {
                        record?.permission_group_router_info.length > 0 ? record?.permission_group_router_info?.map((item, key) => {
                            return <Tag color="magenta" key={key}>{item?.permission_router_info?.name}</Tag>
                        }) : null
                    }
                </div>
            }
        },
        {
            title: 'Trạng thái',
            key: 'status',
            render: record => {
                return <div>
                    {record?.status == 1 ? <Tag color="green">Hoạt động</Tag> : <Tag color="red">Ngưng hoạt động</Tag>}
                </div>
            }
        },
        {
            title: 'Thao tác',
            render: record => {
                return (
                    <div className="box-btn-list">
                        <Button type={'default'} style={{ marginRight: 5 }}>
                            <Link to={RouterLink.Permission.Edit(record?.id)}>Sửa</Link>
                        </Button>
                    </div>
                )
            },
        }
    ];

    useEffect(() => {
        fetch({ perPage: params?.perPage, page: params?.page }).then()
    }, [params])

    const fetch = async (params = {}) => {
        setLoading(true);
        const res = await API.Permission.list(params);
        if (res?.status === 200) {
            setData({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
        setLoading(false);
    };

    const handlePage = (value) => {
        setParams({ ...params, page: value })
    }
    return (
        <>
            <Spin spinning={loading}>
                <Table
                    dataSource={data?.data}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{ x: 1200 }}
                    pagination={false}
                />
                <Row justify={'center'} style={{ marginTop: '10px' }}>
                    <Col>
                        <Pagination
                            current={params.page}
                            onChange={(values) => handlePage(values)}
                            total={data?.total}
                            showSizeChanger={true}
                            defaultPageSize={params?.perPage}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </Col>
                </Row>
            </Spin>
        </>
    )
}