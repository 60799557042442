import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Pagination, Row, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { RouterLink } from "../../constants/constants";
import API from "../../service/API";
import Search from './search';
import { routerRoot } from './contants';
import { useSelector } from 'react-redux'

export default function List() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: [], total: 0 });
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const history = useHistory();
    const currentUser = useSelector(state => state.currentUser)

    const columns = [
        {
            title: <strong>#</strong>,
            render: (text, record, index) => <div>{(params?.page - 1) * params?.limit + (index + 1)}</div>,
        },
        {
            title: 'Tên xã',
            render: record => {
                return <div>
                    <div>{record?.name}</div>
                </div>
            }
        },
        {
            title: 'Tổng số thôn',
            render: record => {
                return <div>
                    <div>{record?.village_info_count}</div>
                </div>
            }
        },
        {
            title: 'Thao tác',
            render: record => {
                return (
                    <div className="box-btn-list">
                        <Button type={'default'} style={{ marginRight: 5 }}>
                            <Link to={RouterLink.Village.View(record?.id)}>Xem thông tin thôn</Link>
                        </Button>
                        <Button type={'default'} style={{ marginRight: 5 }}>
                            <Link to={RouterLink[routerRoot].Edit(record?.id)}>Sửa</Link>
                        </Button>
                        <Button type={'default'} onClick={() => window.open(`https://api.sodiachinhbudang.xyz/wards/export/${record?.id}`)}>
                            Xuất file excel
                        </Button>
                        {/* {
                            record?.status == 1 ? <Button type={'danger'} onClick={() => confirmDeActiveUser(record?.id, 2)}>Xóa</Button>
                                : <Button onClick={() => confirmDeActiveUser(record?.id, 1)}>Mở khóa</Button>
                        } */}

                    </div>
                )
            },
        }
    ];

    const confirmDeActiveUser = (id, status) => {
        Modal.confirm({
            title: 'Thông báo!',
            icon: <ExclamationCircleOutlined />,
            content: status === 2 ? 'Bạn có chắc muốn xóa!' : 'Bạn có chắc muốn kích hoạt',
            okText: 'Đồng ý',
            cancelText: 'Hủy',
            onOk: async () => {
                await API[routerRoot].UpdateStatus(id, { status: status });
                fetch(params).then()
            }
        });
    };

    useEffect(() => {
        fetch(params).then()
    }, [params]);

    const fetch = async (params = {}) => {
        setLoading(true);
        const res = await API[routerRoot].getData(params);
        if (res?.status === 200) {
            setData({
                data: res?.data?.data,
                total: res?.data?.total,
            })
        }
        setLoading(false);
    };

    const handleSearch = (values) => {
        setParams({ ...params, ...values })
    }

    const handlePage = (page, perPage) => {
        setParams({ ...params, page: page, limit: perPage })
    };

    return (
        <div>
            <Search data={(value) => handleSearch(value)} />
            <Row style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 4 }}><Button type={'primary'} onClick={() => history.push(RouterLink[routerRoot].Create)}>Tạo mới</Button></Col>
            </Row>
            <Spin spinning={loading}>
                <Table
                    dataSource={data?.data}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{ x: 1200 }}
                    pagination={false}
                />
                <Row justify={'center'} style={{ marginTop: '10px' }}>
                    <Col>
                        <Pagination
                            current={params.page}
                            onChange={(page, perPage) => handlePage(page, perPage)}
                            total={data?.total}
                            showSizeChanger={true}
                            defaultPageSize={params?.perPage}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
