import axios from 'axios'
import { notification } from "antd";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";
import React from "react";

let assignObjectHeader = {
};

const URL_GET_FROM_ENV = process?.env?.REACT_APP_DEV_LINK || document.getElementById('BACKEND_DEV_LINK')?.innerText
let URL_BASE_ADMIN = URL_GET_FROM_ENV

/*axit post*/
export async function axiosPOST(Url, param = {}, header = {}) {
    Object.assign(header, assignObjectHeader);
    return axios.post(URL_BASE_ADMIN + Url, param, { headers: header }).then(res => {
        if (res?.data?.status) {
            if (!res?.data?.skipMessage) {
                notification.open({
                    message: res?.data?.message,
                    icon: <CheckOutlined style={{ color: '#61ff00' }} />,
                });
            }
        }
        return res.data;
    }).catch(err => {
        if (err?.response?.status === 500) {
            notification.open({
                message: err?.response?.statusText,
                icon: <WarningOutlined style={{ color: 'red' }} />,
            });
            return Promise.reject(err);
        }
        if (err?.response?.data?.message) {
            // notification.open({
            //     message: err?.response?.data?.message,
            //     icon: <WarningOutlined style={{color: 'red'}}/>,
            // });
        } else {
            // const message = err?.response?.statusText ? err?.response?.statusText : 'Network Error';
            // notification.open({
            //     message: message,
            //     icon: <WarningOutlined style={{color: 'red'}}/>,
            // });
        }
        return err?.response;
        return Promise.reject(err);
    })
}

/*axit get*/
export async function axiosGET(Url, header = {}) {
    Object.assign(header, assignObjectHeader);
    return (await axios.get(URL_BASE_ADMIN + Url, {}, { headers: header }).then(res => {
        return res.data;
    }).catch(err => {
        console.log(err, Url, 'Url')
        if (err?.response?.data?.message) {
            notification.open({
                message: err?.response?.data?.message,
                icon: <WarningOutlined style={{ color: 'red' }} />,
            });
        } else {
            // const message = err?.response?.statusText ? err?.response?.statusText : 'Network Error';
            // notification.open({
            //     message: message,
            //     icon: <WarningOutlined style={{color: 'red'}}/>,
            // });
        }

        return err?.response;
        return Promise.reject(err);
        // return err.response;
    }));
}
