import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, notification, Row, Spin} from "antd";
import API from "../../service/API";
import {useDispatch} from "react-redux";
import {LOGIN_ADMIN} from "../../constants/constants";
import {CheckOutlined, CloseCircleOutlined} from '@ant-design/icons'

export default function Login() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() =>{
        // form.setFieldsValue({username: 'dvanh271295'});
        // form.setFieldsValue({password: '123456'});
    }, [form]);

    const onFinish = async (values) => {
        setLoading(true)
        const data = {
            username: values?.username,
            password: values?.password,
        }
        const res = await API.Auth.login(data).catch((res) => {})
        if(res?.status === 200) {
            localStorage.setItem('user_admin', JSON.stringify(res?.data));
            dispatch({'type': LOGIN_ADMIN, payload: res?.data});
            notification.open({
                message: 'Đăng nhập thành công',
                icon: <CheckOutlined style={{ color: '#5FEE37FF' }} />,
            });
        } else {
            notification.open({
                message: res?.data?.message,
                icon: <CloseCircleOutlined style={{ color: '#ff0000' }} />,
            });
        }
        setLoading(false)
    }

    return (
        <div className="box-form-login">
            <div className="login-warp">
                <div className="title-login">Đăng nhập quản trị</div>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="username"
                               rules={[{required: true, message: 'Vui lòng nhập tài khoản đăng nhập!'}]}>
                        <Input placeholder={'Tên đăng nhập'}/>
                    </Form.Item>
                    <Form.Item name="password" rules={[{required: true, message: 'Vui lòng nhập mật khẩu đăng nhập!'}]}>
                        <Input.Password placeholder={'Mật khẩu'}/>
                    </Form.Item>
                    <Row justify={'center'}>
                        <Col>
                            <Spin spinning={loading}>
                                <Button type="primary" htmlType="submit">Đăng nhập</Button>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}
