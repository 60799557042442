import React, {useEffect, useState} from 'react';
import {Button, Menu, Layout} from "antd";
// import LogoHeader from '../../assets/images/logo.svg';
import {ScheduleOutlined, MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons'
import {LOGOUT_ADMIN} from "../../constants/constants";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ListMenuDefault} from "../../constants/constants";
import { useHistory } from 'react-router';
import {checkPermissionUrl} from "../../Helper/helpFunction";
const {Header, Content, Sider} = Layout;

function LayoutApp(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const currentUser = useSelector(state => state.currentUser);

    const logoutUserAdmin = () => {
        localStorage.removeItem('user_admin');
        dispatch({'type': LOGOUT_ADMIN, payload: null});
        history.push('/admin/login')
    };

    let listPermissionUser = [];
    if(currentUser?.user?.permission_group_name_info?.permission_group_router_info) {
        currentUser?.user?.permission_group_name_info?.permission_group_router_info.map((item) => {
            listPermissionUser.push(item?.router_name)
        })
    }

    return (
        <div>
            <Layout>
                <Header className={`header-content`}>
                    <div className="row-header-menu-top">
                        <div className={`left-header-logo ${collapsed ? '' : 'active-menu'}`}>
                            <Button type="primary" onClick={() => setCollapsed(!collapsed)}
                                    className="btn-header-collapsed">
                                {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            </Button>
                            {/* <img onClick={() => history.push('/admin/index')} src={LogoHeader} alt={'logo header'} className="header-logo-left"/> */}
                        </div>
                        <div className="right-header-menu">
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                                <Menu.Item key="1"> <Button type={"primary"} danger
                                                            onClick={() => logoutUserAdmin()}>Logout</Button></Menu.Item>
                            </Menu>
                        </div>
                    </div>
                </Header>
                <Layout>
                    <Sider width={collapsed ? 0 : 230} className="site-layout-background">
                        <Menu
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            mode="inline"
                            theme="dark"
                        >
                            {
                                ListMenuDefault.map((item, key) => {
                                    if (item?.children && item?.children.length > 0) {
                                        const statusMenu1 = checkPermissionUrl(item, listPermissionUser)
                                        if(statusMenu1) {
                                            return (
                                                <Menu.SubMenu key={'menu-' + key} icon={item.icon} title={item.title}>
                                                    {
                                                        item?.children.map((itemC, keyC) => {
                                                            const statusMenu2 = checkPermissionUrl(itemC, listPermissionUser)
                                                            if(statusMenu2) {
                                                                return (
                                                                    <Menu.Item key={'menu-' + key + '-' + keyC + keyC}><Link
                                                                        to={itemC.link}>{itemC.icon} {itemC.title}</Link></Menu.Item>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Menu.SubMenu>
                                            )
                                        }

                                    } else {
                                        const statusMenu1 = checkPermissionUrl(item, listPermissionUser)
                                        if(statusMenu1) {
                                            return (
                                                <Menu.Item icon={null} key={'menu-' + key}>
                                                    <Link to={item.link}>{item.icon} {item.title}</Link>
                                                </Menu.Item>
                                            )
                                        }
                                    }
                                })
                            }
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: '86vh',
                            }}
                        >
                            <div className="content-middle-main">
                                {props.children}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </div>
    )
}

export default LayoutApp
