import { Button, Card, Col, Input, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../service/API";

export default function Search(props) {
    const [params, setParams] = useState({});
    const currentUser = useSelector(state => state.currentUser)

    const handleSearch = () => {
        props?.data(params)
    }

    const handleChangeData = (type, value) => {
        setParams({ ...params, [type]: value })
    }

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <Card title="Tìm kiếm" >
                    <Row gutter={16} style={{ marginBottom: 12 }}>
                        <Col span={3}>
                            <div>Tên thôn</div>
                            <Input onChange={(event) => handleChangeData('name', event.target.value)} />
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginBottom: 12 }}>
                        <Col>
                            <Button type="danger" onClick={() => handleSearch()}>Tìm kiếm</Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}
