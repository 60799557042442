import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, notification, Row, Select, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import API from "../../service/API";
import { RouterLink } from "../../constants/constants";
import { useSelector } from "react-redux";
import { routerRoot } from './contants';

export default function Edit() {
    const [form] = Form.useForm();
    const params = useParams();
    const id = params?.id;
    const history = useHistory();
    const loadingData = useSelector(state => state?.loadingAction);
    const [wards, setWards] = useState({})

    const fetchWards = async () => {
        const res = await API.Wards.getData({ page: 1, limit: 1000 })
        if (res?.status) {
            setWards(res?.data)
        }
    }

    const fetchDetail = async () => {
        const res = await API[routerRoot].detailData(id);
        if (res?.status === 200) {
            form.setFieldsValue({
                name: res?.data?.name ? res?.data.name : null,
                total: res?.data?.total ? res?.data.total : null,
                wards_id: res?.data?.wards_id ? res?.data.wards_id : null,
            })
        }
    };

    useEffect(() => {
        fetchWards().then();
        fetchDetail().then();
    }, []);

    const onFinish = async (values) => {
        const data = {
            name: values?.name ? values.name : null,
            total: values?.total ? values.total : null,
            wards_id: values?.wards_id ? values.wards_id : null,
        };
        const res = await API[routerRoot].updateData(id, data);
        if (res?.status === 200) {
            history.push(RouterLink[routerRoot].List);
        }
    };

    return (
        <div>
            <Spin spinning={loadingData?.loading}>
                <div className="box-create-form">
                    <Form form={form} style={{ width: '40%', margin: 'auto' }}
                        name="basic"
                        onFinish={onFinish}>

                        <Form.Item label="Tên thôn" name="name" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Tổng số thửa" name="total" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Thuộc Xã" name="wards_id" rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]} >
                            <Select>
                                <Select.Option value={''}>Tất cả</Select.Option>
                                {
                                    wards?.data && wards?.data.length > 0 ? wards?.data.map((item, key) => {
                                        return (<Select.Option key={key} value={item?.id}>{item?.name}</Select.Option>)
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Row justify={'center'}>
                            <Col>
                                <Button type={"primary"} htmlType="submit" danger>Lưu</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Spin>
        </div>
    )
};
