import configureStore from './reducers/configureStore'
import { Provider } from "react-redux";
import React from "react";
import Router from "./Router";
import './app.scss'

function App() {
    return (
        <Provider store={configureStore()}>
            <Router />
        </Provider>
    );
}

export default App
